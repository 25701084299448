<template>
  <div class="footer">
    <div class="text">
      <p>© BER LLC</p>
      <p>{{ $t("footer") }}</p>
    </div>
    <div class="contact-links">
      <a target="_blank" href="https://www.facebook.com/berlogistics"
        ><img src="../../../public/fb.svg" alt="" />
      </a>
      <a target="_blank" href="https://wa.me/37444205502"
        ><img src="../../../public/whatsapp.svg" alt="" />
      </a>
      <a target="_blank" href="https://viber://chat?number=37444205502"
        ><img src="../../../public/viber.svg" alt="" />
      </a>
    </div>
    <div class="bg-shape"></div>
  </div>
</template>
<script>
export default {
  name: "FooterComponent",
};
</script>
<style scoped lang="scss">
.footer {
  -webkit-box-shadow: 1px -4px 5px 0px rgba(146, 136, 136, 0.75);
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  .contact-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      margin-inline: 10px;
    }
  }
  .bg-shape {
    background: url("../../../public/footer-shape.svg");
    background-repeat: no-repeat;
    background-position: center right;
    height: 42px;
    width: -webkit-fill-available;
  }
  .text {
    width: 300px;
    padding-left: 130px;
    p {
      font-size: 16px;
      margin: 0;
    }
  }
}
@media screen and (max-width: 1024px) {
  .footer {
    .text {
      padding-left: 15px;
    }
  }
}
@media screen and (max-width: 600px) {
  .footer {
    .text {
      padding-left: unset;
      padding: 15px;
    }
    .bg-shape {
      display: none;
    }
  }
}
</style>
