<template>
  <div id="contactUs" class="get-in-touch">
    <div class="heading">
      <img src="../../public/coub-logo.svg" alt="" />
      <h1 class="section-heading">{{ $t("getInTouch.heading") }}</h1>
    </div>
    <div class="wrapper">
      <div class="left">
        <p>
          {{ $t("getInTouch.text") }}
        </p>
        <div>
          <img class="map-img" src="../../public/map.jpg" alt="" />
        </div>

        <span>{{ $t("getInTouch.address") }}</span>
        <div class="links">
          <a href="mailto: info@ber.im"
            ><img src="../../public/info.svg" alt="" /> info@ber.im</a
          >
          <a href="tel:+37444205502"
            ><img src="../../public/call.svg" alt="" />+374 44 205502
          </a>
        </div>
      </div>
      <form class="form" @submit.prevent>
        <label>{{ $t("form.fullName") }}</label>
        <input v-model="fullName" type="text" />
        <label>{{ $t("form.email") }}</label>
        <input v-model="email" type="text" />
        <label>{{ $t("form.phone") }}</label>
        <input v-model="phone" type="number" />
        <label>{{ $t("form.subject") }}</label>
        <input v-model="subject" type="text" />
        <label>{{ $t("form.message") }}</label>
        <textarea v-model="message" />
        <button type="submit" class="submit-button">
          {{ $t("form.submit") }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "GetInTouch",
  data() {
    return {
      fullName: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    };
  },
};
</script>

<style scoped lang="scss">
.get-in-touch {
  .map-img {
    max-width: 540px;
  }
  width: min(100% - 30px, 1200px);
  margin: auto;
  margin-bottom: 106px;

  .heading {
    display: flex;
    align-self: start;
    padding: 50px 0;
  }

  .form {
    width: 620px;
    background: #3e3e40;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    padding: 20px;
    label {
      display: block;
    }
    input {
      margin-bottom: 20px;
      width: 98%;
      height: 36px;
      border-radius: 6px;
    }
    .submit-button {
      background: #ba141a;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      color: white;
      padding: 8px 38px;
    }
  }

  .wrapper {
    display: flex;
    width: 100%;
    gap: 40px;
  }
  .left {
    width: 580px;
    > .links {
      width: 190px;
      a {
        width: auto;
        text-decoration: none;
        color: #3e3e40;
        &:visited,
        &:active {
          color: #3e3e40;
        }
        img {
          margin-right: 20px;
        }
        padding-top: 35px;
        display: flex;
        align-items: center;
      }
    }
  }
  textarea {
    margin-bottom: 20px;

    width: 99%;
    height: 160px;
    resize: none;
  }
}
@media screen and (max-width: 1024px) {
  .get-in-touch {
    .left {
      width: auto;
      .map-img {
        width: 100%;
      }
    }
    .wrapper {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    .form {
      width: calc(100% - 30px);
    }
    .links {
      display: flex;
      width: auto !important;
      justify-content: center;
    }
  }
}
@media screen and (max-width: 1024px) {
  .get-in-touch {
    .links {
      flex-direction: column;
    }
  }
}
</style>
