<template>
  <div id="service" class="services">
    <div class="heading">
      <img src="../../public/coub-logo.svg" alt="" />
      <h1 class="section-heading">{{ $t("service") }}</h1>
    </div>
    <div>
      <div
        v-for="service in services"
        :key="service.heading"
        class="image-text"
      >
        <img :src="require(`../../public/${service.img}.png`)" alt="" />
        <div class="text">
          <h3 class="small-heading">{{ service.heading }}</h3>
          <p>
            {{ service.text }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServicesSection",
  computed: {
    services() {
      return [
        {
          img: "stock4",
          heading: this.$t("services.heading1"),
          text: this.$t("services.service1"),
        },
        {
          img: "stock1",
          heading: this.$t("services.heading2"),
          text: this.$t("services.service2"),
        },
        {
          img: "stock2",
          heading: this.$t("services.heading3"),
          text: this.$t("services.service3"),
        },
        {
          img: "stock3",
          heading: this.$t("services.heading4"),
          text: this.$t("services.service4"),
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.services {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  width: min(100% - 30px, 1200px);
  margin: auto;

  .heading {
    display: flex;
    align-self: start;
    padding: 50px 0;
  }

  .image-text {
    gap: 20px;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-bottom: 16px;
    > img {
      max-width: 500px;
    }

    &:nth-of-type(even) {
      img {
        order: 2;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .services {
    .image-text {
      flex-direction: column;
      img {
        width: 100%;
        max-width: unset;
      }
      &:nth-of-type(even) {
        img {
          order: unset !important;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .image-text {
    img {
      width: 400px;
    }
  }
}
</style>
