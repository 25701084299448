<template>
  <div
    class="flag-icon"
    v-for="lang in supportedLanguages"
    :key="lang"
    @click="changeLanguage(lang)"
  >
    <country-flag :country="lang" size="large" />
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag-next";

export default {
  components: {
    CountryFlag,
  },
  data() {
    return {
      supportedLanguages: ["am", "ru", "us"],
    };
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
    },
  },
};
</script>

<style scope lang="scss">
.flag-icon {
  display: inline-flex;
  margin-left: 15px;
  cursor: pointer;
}
</style>
