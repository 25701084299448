<template>
  <div id="header" class="header">
    <div v-if="windowWidth < 1024">
      <Slide :closeOnNavigation="true">
        <a class="logo-link" href="https://www.ber.im">
          <img src="../../../public/Logo.svg" alt="" />
        </a>
        <ul class="mobile-list">
          <li @click="handleClick('home')">{{ $t("home") }}</li>
          <li @click="handleClick('service')">{{ $t("service") }}</li>
          <li @click="handleClick('about')">{{ $t("about") }}</li>
          <li @click="handleClick('benefits')">{{ $t("benefit") }}</li>
          <li @click="handleClick('contactUs')">{{ $t("contactUs") }}</li>
        </ul>
        <div class="flags flex-wrapper"><flag-select></flag-select></div>
      </Slide>
    </div>

    <div v-else>
      <a class="logo-link" href="https://www.ber.im">
        <img src="../../../public/Logo.svg" alt="" />
      </a>
      <div class="flex-wrapper">
        <ul>
          <li @click="handleClick('home')">{{ $t("home") }}</li>
          <li @click="handleClick('service')">{{ $t("service") }}</li>
          <li @click="handleClick('about')">{{ $t("about") }}</li>
          <li @click="handleClick('benefits')">{{ $t("benefit") }}</li>
          <li @click="handleClick('contactUs')">{{ $t("contactUs") }}</li>
        </ul>
        <flag-select></flag-select>
      </div>
    </div>
  </div>
</template>
<script>
import FlagSelect from "../FlagSelect.vue";
import { Slide } from "vue3-burger-menu"; // import the CSS transitions you wish to use, in this case we are using `Slide`

export default {
  name: "HeaderComponent",
  components: { FlagSelect, Slide },
  data() {
    return {
      windowWidth: window.innerWidth,
      isOpen: this.windowWidth < 1024,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    handleClick(ref) {
      let el = document.getElementById(ref);
      el.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>
<style lang="scss">
.header {
  -webkit-box-shadow: 1px 4px 5px 0px rgba(146, 136, 136, 0.75);
  position: fixed;
  height: 68px;
  top: 0;
  z-index: 999;
  background: white;
  width: 100%;

  > div {
    display: flex;
    justify-content: space-between;
    padding: 0 130px;
    .flex-wrapper {
      display: flex;
      align-items: center;
    }
  }
  .logo-link {
    display: flex;
    align-items: center;
  }
  .flags {
    padding: 0;
    gap: 30px;

    & .flag-icon {
      margin-left: 0;
      -webkit-box-shadow: none;
    }
  }
  .mobile-list {
    * {
      text-wrap: nowrap;
    }
    flex-direction: column;
    margin: 0;
    li {
      text-transform: uppercase;
      font-size: 14px;
      padding: 8px 6px;
      color: grey;
      cursor: pointer;
      &:nth-last-child(1) {
        text-align: center;
        background: #ba141a;
        border-radius: 4px;
        color: white;
        min-width: 80px;
      }
    }
  }
  .bm-burger-button {
    left: 20px;
    top: 20px;
  }

  .bm-menu {
    background-color: #ebebeb !important;
  }
  ul {
    display: flex;
    list-style: none;
    padding-left: unset;
    li {
      text-transform: uppercase;
      font-size: 14px;
      padding: 8px 6px;
      cursor: pointer;
      &:nth-last-child(1) {
        text-align: center;
        background: #ba141a;
        border-radius: 4px;
        color: white;
        min-width: 80px;
      }
    }
  }
}
@media screen and (max-width: 1356px) {
  .header {
    > div {
      justify-content: space-around;
      padding: 0;
    }
  }
}
</style>
