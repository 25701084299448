<template>
  <div id="about" class="who-we-are">
    <div class="heading">
      <img src="../../public/coub-logo.svg" alt="" />
      <h1 class="section-heading">{{ $t("whoWeAre.heading") }}</h1>
    </div>
    <div class="texts">
      <div v-for="service in services" :key="service.heading" class="text">
        <p v-html="service.text"></p>
      </div>
      <div class="text">
        <img src="../../public/truck1.svg" alt="" />
        <img src="../../public/truck2.svg" alt="" />
        <img src="../../public/truck3.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServicesSection",
  computed: {
    services() {
      return [
        {
          text: this.$t("whoWeAre.service1"),
        },
        {
          text: `<p style='color: #880b18; margin: 0' >${this.$t(
            "whoWeAre.service4"
          )}<p/>${this.$t("whoWeAre.service3")}`,
        },
        {
          text: this.$t("whoWeAre.service2"),
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.who-we-are {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  width: min(100% - 30px, 1200px);
  margin: auto;

  .heading {
    display: flex;
    align-self: start;
    padding: 50px 0;
  }

  .text {
    padding: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .red-color {
      color: #880b18 !important;
    }
  }
  .texts {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    &:nth-of-type(even) {
      img {
        order: 2;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .who-we-are {
    .texts {
      display: flex;
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 600px) {
  .who-we-are {
    .texts {
      &:nth-of-type(even) {
        img {
          width: 100px;
        }
      }
    }
  }
}
</style>
