<template>
  <div id="home" class="home">
    <div>
      <p class="main-text">
        {{ $t("bannerText") }}
      </p>
      <div class="scroll-btn" @click="handleClick">
        <span>{{ $t("scroll") }}</span
        ><img src="../../public/arrow-down.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeSection",
  methods: {
    handleClick() {
      let el = document.getElementById("service");
      el.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style scoped lang="scss">
.home {
  background: url(../assets/Banner.png);
  position: relative;
  display: flex;
  align-items: end;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 92vh;
  > div {
    margin: 50px 0;
    position: absolute;
    justify-content: center;
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    span {
      letter-spacing: 0.35em;
      font-size: 16px;
      text-transform: uppercase;
    }
    > div {
      justify-content: center;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
  .main-text {
    margin-bottom: 40px;
    font-size: 36px;
    bottom: 0;
    color: white;
    text-align: center;
    margin-top: 0;
  }
  span {
    bottom: 0;
    color: white;
    text-align: center;
  }
  .scroll-btn {
    cursor: pointer;
    &:hover {
      img {
        animation: slide-bottom 0.5s infinite;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .home {
    .main-text {
      font-size: 24px;
    }
  }
}

@keyframes slide-bottom {
  0% {
    transform: translateY(0);
    transition: 1s;
  }
  50% {
    transform: translateY(10px);
    transition: 1s;
  }
  100% {
    transform: translateY(0);
    transition: 1s;
  }
}
.home::before {
  content: "";
  position: absolute;
  right: 0px;
  top: 0;
  bottom: 0px;
  left: 0px;
  background-color: rgb(14, 13, 13);
  -webkit-mask-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
}

@media screen and (max-width: 1024px) {
  .home {
    height: 72vh;
  }
}
@media screen and (max-width: 600px) {
  .home {
    background-position: 76%;
  }
}
</style>
