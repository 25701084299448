<template>
  <div id="benefits" class="benefits">
    <div class="heading">
      <img src="../../public/coub-logo.svg" alt="" />
      <h1 class="section-heading">{{ $t("benefits.heading") }}</h1>
    </div>
    <ul class="benefit-icons">
      <li
        v-for="(tab, index) in tabs"
        :key="index"
        :class="{ active: activeTab === index }"
      >
        <a href="#" @click.prevent="setActiveTab(index)"
          ><BenefitSvg :name="`tab${index + 1}`"
        /></a>
      </li>
    </ul>
    <div class="benefit-content">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        :class="{ active: activeTab === index }"
      >
        <h3 class="small-heading">{{ tab.heading }}</h3>
        <p>
          {{ tab.text }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import BenefitSvg from "./BenefitSvg.vue";
export default {
  name: "BenefitsSection",
  components: {
    BenefitSvg,
  },
  computed: {
    tabs() {
      return [
        {
          heading: this.$t("benefits.heading1"),
          text: this.$t("benefits.text1"),
        },
        {
          heading: this.$t("benefits.heading2"),
          text: this.$t("benefits.text2"),
        },
        {
          heading: this.$t("benefits.heading3"),
          text: this.$t("benefits.text3"),
        },
        {
          heading: this.$t("benefits.heading4"),

          text: this.$t("benefits.text4"),
        },
      ];
    },
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  methods: {
    setActiveTab(index) {
      this.activeTab = index;
    },
  },
};
</script>

<style lang="scss">
.benefits {
  width: min(100% - 30px, 1200px);
  margin: auto;
  .heading {
    display: flex;
    align-self: start;
    padding: 50px 0;
  }
  .benefit-icons {
    display: flex;
    padding: 0;
    justify-content: space-between;

    li {
      list-style-type: none;
      &.active {
        border-bottom: 3px solid #880b18;
        padding-bottom: 10px;
        a {
          .svg-wrapper {
            path:nth-child(1),
            path:nth-child(2) {
              fill: #880b18;
            }
          }
        }
      }
    }
  }
  .benefit-content {
    > div {
    }
    justify-content: space-between;
    align-items: end;
    display: flex;
    padding-top: 50px;
    > div {
      display: none;
      &.active {
        display: block;
      }
    }
  }
  .contact-button {
    text-transform: uppercase;
    font-size: 16px;
    padding: 8px 10px;
    background: #ba141a;
    border-radius: 4px;
    color: white;
    height: 22px;
  }
}
@media screen and (max-width: 1024px) {
  .benefit-icons {
    display: flex;
    padding: 20px;
    justify-content: space-between;
    a {
      svg {
        width: 100px;
      }
      .svg-wrapper {
        path:nth-child(1),
        path:nth-child(2) {
          fill: #880b18;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .benefits {
    .heading {
      padding: 50px 0;
    }
  }
  .benefit-icons {
    a {
      svg {
        width: 75px;
      }
    }
  }
}
</style>
