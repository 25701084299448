import { createApp } from 'vue'
import App from './App.vue'
import { createI18n } from 'vue-i18n'

const messages = {
     us: require('./locales/us.json'),
     ru: require('./locales/ru.json'),
     am: require('./locales/am.json')
};

const i18n = createI18n({
     locale: 'am',
     fallbackLocale: 'am',
     messages
})


const app = createApp(App)

app.use(i18n)
app.mount('#app')