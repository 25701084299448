<template>
  <div id="app">
    <Header />
    <HomeSection />
    <ServicesSection />
    <WhoWeAreSection />
    <BenefitsSection />
    <GetInTouch />
    <Footer />
  </div>
</template>

<script>
import HomeSection from "./components/HomeSection.vue";
import ServicesSection from "./components/ServicesSection.vue";
import WhoWeAreSection from "./components/WhoWeAreSection.vue";
import BenefitsSection from "./components/BenefitsSection.vue";
import GetInTouch from "./components/GetInTouch.vue";
import Header from "./components/Layout/Header.vue";
import Footer from "./components/Layout/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
    HomeSection,
    ServicesSection,
    BenefitsSection,
    WhoWeAreSection,
    GetInTouch,
  },
};
</script>
<style scoped lang="scss">
#app {
  > div:not(.footer, .header) {
    padding-top: 68px;
  }
}

</style>
